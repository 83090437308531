import React from 'react';

const FilterButtons = ({ filter, setFilter }) => {
  return (
    <div className="flex justify-between items-center flex-container-buttons">
      <button
        className={`filter-button ${filter === "selected" ? "bg-white text-[#0071bd]" : "filter-button-active text-white"}`}
        onClick={() => setFilter("selected")}
      >
        All
      </button>
      <button
        className={`filter-button ${filter === "offered" ? "bg-white text-[#0071bd]" : "filter-button-active text-white"}`}
        onClick={() => setFilter("offered")}
      >
        Offered
      </button>
      <button
        className={`filter-button ${filter === "payed" ? "bg-white text-[#0071bd]" : "filter-button-active text-white"}`}
        onClick={() => setFilter("payed")}
      >
        Payed
      </button>
      <button
       disabled={true}
        className={`filter-button-disabled ${filter === "schedules" ? "" : "filter-button-disabled  "}`}
        // onClick={() => setFilter("schedules")}
      >
        Schedules
      </button>
    </div>
  );
};

export default FilterButtons;
