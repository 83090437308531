import React, { useEffect, useState, useRef } from "react";
// import { LineChart, XAxis, YAxis, Line } from "recharts";
import { getAuth } from "firebase/auth";
import EditPopup from "./EditPopup";
import AddPopup from "./AddPopup";
// import { collection, addDoc, getDocs, deleteDoc, doc, query, where } from 'firebase/firestore';
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  getStorage,
} from "firebase/storage";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  updateDoc,
  query,
  where,
  setDoc,
} from "firebase/firestore";
import { app, storage } from "../firebase";
import { ReactComponent as LogoutIcon } from "../logout.svg"; // Import the logout SVG icon
import "../App.css"; // Ensure to include the CSS
import "../css/Dashboard.css"; // Ensure to include the CSS
import Loader from "./Loader";
import ConfirmationPopup from "./ConfirmationPopup";
import * as XLSX from "xlsx-js-style";
import {
  AddIcon,
  CurrencyIcon,
  ExcelIcon,
  GreenDeleteIcon,
  PdfIcon,
} from "./Icons";
import FilterButtons from "./FilterButtons"; // Import the new FilterButtons component
import CandidateSearchExport from "./CandidateSearchExport"; // Import the new component
import CandidateCard from "./CandidateCard"; // Import the new component
import SchedulesSelection from "./SchedulesSelection";

function Dashboard({ candidates }) {
  const EXCEL_TYPE =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const EXCEL_EXTENSION = ".xlsx";
  const [candidatesData, setCandidatesData] = useState([]);
  const [filter, setFilter] = useState("selected");
  const [file, setFile] = useState(null);
  const fileRef = useRef(null);
  const [files, setFiles] = useState([]);
  const db = getFirestore(app);
  const auth = getAuth(app);
  const userLoggedIn = JSON.parse(localStorage.getItem("user")) ?? {};
  const [user, setUserData] = useState(null);
  const [resources, setResources] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState(null); // State to hold the selected candidate for editing
  const [isEditPopupOpen, setEditPopupOpen] = useState(false); // State to manage popup visibility
  const [isAddPopupOpen, setAddPopupOpen] = useState(false); // State to manage popup visibility
  const [tempCandidatesData, setTempCandidatesData] = useState([]); // State to hold the temporary candidates data for adding new candidates
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  /**
   * Handles the click event for deleting a candidate.
   * @param {number} index - The index of the candidate to delete.
   */
  const handleDeleteClick = (index) => {
    setShowDeletePopup(true);
    setSelectedCandidate(candidatesData[index]);
  };

  /**
   * Exports the given JSON data as an Excel file.
   * @param {Array} json - The data to export.
   * @param {string} excelFileName - The name of the Excel file.
   */
  const exportAsExcelFile = (json, excelFileName) => {
    json = JSON.parse(JSON.stringify(json));

    // Define the keys you want to include in the export  
    const keys = [

      "contact",
      "createdBy",
      "createdRole",
      "currentCTC",
      "emailId",
      "experience",
      "fullName",
      "handledBy",
      "interviewProgress",
      "isOffered",
      "isPayed",
      "isSelected",
      "technology",
      "vendorName"
    ];

    if (user?.role === "ACCENTURETEAM") {
      keys.push(
        "candidateEmailPassword",
        "cid",
        "currentCompanyName",
        "currentLocation",
        "firstRoundPanelName",
        "noticePeriod",
        "prefferedLocation",
        "previousCompanyName",
        "referenceName",
        "relevantExperience",
        "secondRoundPanelName"
      );
    }


    if (!isSuperAdmin()) {
      json = json.filter((candidate) =>
        candidate.createdBy === user?.emailId || candidate.createdRole === user?.role
      );

      // Eliminate unwanted properties  
      json = json.map((candidate) => {
        const { paidAmount, balance, url, resume, ...filtered } = candidate;
        return filtered;
      });
    } else {
      json = json.map((candidate) => {
        const { url, resume, ...filtered } = candidate;
        return filtered;
      });
    }

    // Filter candidates based on the same logic as in filterCandidates  
    json = json.filter((candidate) => {
      if (filter === "selected") {
        return (
          (candidate.isSelected || !candidate.isSelected) &&
          !candidate.isOffered &&
          !candidate.isPayed
        );
      } else if (filter === "offered") {
        return (
          candidate.isSelected && candidate.isOffered && !candidate.isPayed
        );
      } else if (filter === "payed") {
        return candidate.isSelected && candidate.isOffered && candidate.isPayed;
      }
      return false;
    });

    // Create a new array with only the keys we want in the order we want  
    const formattedJson = json.map(candidate => {
      return keys.reduce((obj, key) => {
        obj[key] = candidate[key] || ''; // Assign empty string if key doesn't exist  
        return obj;
      }, {});
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedJson, { header: keys });

    // Apply styling to the header row  
    for (let i in worksheet) {
      if (Number(i.toString()?.split("")[1]) === 1) {
        if (typeof worksheet[i] !== "object") continue;
        worksheet[i].s = {
          font: {
            name: "Arial",
            bold: true,
            color: { rgb: "0071DB" },
          },
          alignment: {
            vertical: "center",
            horizontal: "center",
            wrapText: true,
          },
        };
      }
    }

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet, "Sheet1");
    XLSX.writeFile(
      wb,
      `${excelFileName}_export_${new Date().getTime()}_${filter}${EXCEL_EXTENSION}`
    );
  };

  /**
   * Confirms the deletion of a candidate.
   */
  const handleConfirmDelete = async () => {
    setShowDeletePopup(false);
    if (selectedCandidate.url) {
      await deleteFileByNameOutput(selectedCandidate.resume);
    }
    deleteCandidate(selectedCandidate.id);
  };

  const handleCloseDeletePopup = () => {
    setShowDeletePopup(false);
  };

  useEffect(() => {
    checkIsLoggedIn();
    setLoader(true);
    fetchFiles();
  }, []);

  /**
   * Checks if the user is logged in and redirects if not.
   */
  const checkIsLoggedIn = async () => {
    const userLoggedIn = JSON.parse(localStorage.getItem("user")) ?? "{}";

    if (!user && !userLoggedIn?.hasOwnProperty("emailId")) {
      window.location.href = "/";
    } else {
      const emailId = userLoggedIn?.emailId;
      if (emailId) {
        const querySnapshot = await getDocs(
          query(collection(db, "BDUsers"), where("emailId", "==", emailId))
        );
        if (querySnapshot.docs.length === 0) {
          window.location.href = "/";
        } else {
          const userData = await querySnapshot.docs[0].data();
          const userObj = {
            emailId: userData?.emailId,
            accessToken: userLoggedIn?.accessToken,
            role: userData?.role,
          };
          setUserData(userObj);
          localStorage.setItem("user", JSON.stringify(userObj));
          fetchCandidates(userObj);
          fetchResources();
        }
      } else {
        console.error("emailId is undefined");
      }
    }
  };

  /**
   * Fetches candidates from Firestore based on the logged-in user.
   * @param {Object} userObj - The user object containing user details.
   */
  const fetchCandidates = async (userObj) => {
    setCandidatesData([]);
    setLoader(true);
    const querySnapshot = await getDocs(collection(db, "BDProfiles"));
    const candidatesData = querySnapshot.docs.map((doc) => doc.data());
    fetchCandidatesByCreatedBy(candidatesData, userObj);
  };

  /**
   * Fetches candidates created by the specified user.
   * @param {Array} candidates - The list of candidates to filter.
   * @param {Object} userObj - The user object containing user details.
   */
  const fetchCandidatesByCreatedBy = async (candidates, userObj) => {
    if (isSuperAdmin() && userObj?.emailId) {
      const filteredCandidates = candidates.filter(
        (candidate) =>
          candidate.createdBy === userObj?.emailId ||
          isSuperAdmin() ||
          candidate.createdRole === userObj?.role
      );
      // let candidatesData =
      //   candidates.filter(
      //     (candidate) =>
      //       candidate.createdBy == userObj?.emailId || isSuperAdmin()
      //   ) ?? [];
      // candidatesData.push([
      //   ...candidatesData.filter(
      //     (candidate) => candidate.createdRole == userObj?.role
      //   ),
      // ]);

      setCandidatesData(filteredCandidates);
      setTempCandidatesData(JSON.parse(JSON.stringify(filteredCandidates)));
      removeLoader();
    } else {
      if (userObj?.emailId) {
        const filteredCandidates = candidates.filter(
          (candidate) =>
            candidate.createdBy === userObj?.emailId ||
            isSuperAdmin() ||
            candidate.createdRole === userObj?.role
        );
        setCandidatesData(filteredCandidates);
        setTempCandidatesData(JSON.parse(JSON.stringify(filteredCandidates)));
        removeLoader();
      }
    }
  };

  /**
   * Formats a number into Indian currency format.
   * @param {number} value - The value to format.
   * @returns {string} - The formatted currency string.
   */
  const formatAmount = (value) => {
    if (value === null || value === undefined) {
      return "";
    }

    // Convert the number to a string
    const numberString = value.toString();

    // Split the number on the decimal point
    const parts = numberString.split(".");
    let integerPart = parts[0];
    const decimalPart = parts[1] ? "." + parts[1] : "";

    // Indian Number Formatting
    const lastThreeDigits = integerPart.substring(integerPart.length - 3);
    const otherDigits = integerPart.substring(0, integerPart.length - 3);

    // If there are digits before the last three
    if (otherDigits !== "") {
      integerPart =
        otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
        "," +
        lastThreeDigits;
    }

    return integerPart + decimalPart;
  };
  // Upload file
  const uploadFile = async () => {
    setLoader(true);
    if (file) {
      const storageRef = ref(storage, `files/${file.name}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      alert(url);

      // Save file metadata to Firestore
      await addDoc(collection(db, "files"), { name: file.name, url });
      setFile(null);
      fetchFiles();
      removeLoader();
    }
  };

  /**
   * Updates the candidate data in Firestore.
   * @param {string} id - The ID of the candidate to update.
   * @param {Object} data - The updated candidate data.
   */
  const updateCandidates = async (id, data) => {
    await updateDoc(doc(db, "BDProfiles", id), data);
    fetchCandidates(JSON.parse(localStorage.getItem("user")));
  };

  /**
   * Deletes a candidate from Firestore.
   * @param {string} id - The ID of the candidate to delete.
   */
  const deleteCandidate = async (id) => {
    await deleteDoc(doc(db, "BDProfiles", id));
    fetchCandidates(JSON.parse(localStorage.getItem("user")));
  };

  /**
   * Checks if the user is a super admin.
   * @returns {boolean} - True if the user is a super admin, false otherwise.
   */
  const isSuperAdmin = () => {
    return user?.role === "SUPERADMIN" || userLoggedIn?.role === "SUPERADMIN";
  };

  const isAccentureTeam = () => {
    return (
      user?.role === "ACCENTURETEAM" || userLoggedIn?.role === "ACCENTURETEAM"
    );
  };

  const isNotSuperAdmin = () => {
    return !isSuperAdmin();
  };

  /**
   * Creates a new candidate in Firestore.
   * @param {Object} data - The candidate data to create.
   */
  const createCandidates = async (data) => {
    try {
      //Prepare Data for adding to firestore
      data.isSelected = data?.isSelected || false;
      data.isOffered = data?.isOffered || false;
      data.isPayed = data?.isPayed || false;
      data.interviewProgress = data?.interviewProgress || null;
      data.resume = data?.resume || null;
      data.url = data?.url || null;
      data.balance = data?.balance || 0;
      data.paidAmount = data?.paidAmount || 0;
      data.createdBy = user?.emailId || null;
      data.createdRole = user?.role || null;
      const newCandidateRef = doc(collection(db, "BDProfiles")); // Create a reference with a generated ID

      // Add the generated ID to the data object
      const candidateDataWithId = {
        id: newCandidateRef.id, // Assign the generated ID
        ...data, // Spread the existing data
      };

      // Now add the document to Firestore with the ID
      await setDoc(newCandidateRef, candidateDataWithId); // Use the reference to add the document

      fetchCandidates(JSON.parse(localStorage.getItem("user")));
    } catch (error) {
      console.error("Error adding document: ", error);
      removeLoader(false);
      fetchCandidates(JSON.parse(localStorage.getItem("user")));
    }
  };

  // Fetch files
  const fetchFiles = async () => {
    const querySnapshot = await getDocs(collection(db, "files"));
    const filesList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setFiles(filesList);
  };

  // Fetch file by name
  const fetchFileByName = async (fileName) => {
    const q = query(collection(db, "files"), where("name", "==", fileName));
    const querySnapshot = await getDocs(q);
    const filesList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return filesList; // Return the list of files matching the name
  };

  /**
   * Removes the loading indicator after a delay.
   * @param {boolean} isLoading - Indicates if loading is in progress.
   */
  const removeLoader = (isLoading) => {
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };

  const [loader, setLoader] = useState(false);
  const logOut = async () => {
    await auth.signOut();
    window.location.href = "/";
    localStorage.clear();
  };

  const setAddPopupOpenFunc = () => {
    setAddPopupOpen(true);
  };
  const downloadFile = async (file) => {
    let fileUrl = file.url;
    setLoader(true);
    if (!fileUrl) {
      console.error("No file URL provided.");
      return;
    }
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) throw new Error("Network response was not ok");
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file.name); // Provide a name for the file
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      removeLoader();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const downloadResumeByUrl = async (fileUrl) => {
    setLoader(true);
    if (!fileUrl) {
      console.error("No file URL provided.");
      return;
    }
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) throw new Error("Network response was not ok");
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "resume.pdf"); // Provide a name for the file
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      removeLoader();
    } catch (error) {
      setLoader(false);
      console.error("Error downloading file:", error);
    }
  };
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  /**
   * Opens the edit popup for the selected candidate.
   * @param {Object} candidate - The candidate to edit.
   */
  const openEditPopup = (candidate) => {
    setSelectedCandidate(candidate); // Set the selected candidate
    setEditPopupOpen(true); // Open the popup
  };

  /**
   * Handles the submission of the edit form for a candidate.
   * @param {Object} updatedData - The updated candidate data.
   */
  const handleEditSubmit = async (updatedData) => {
    setLoader(true);
    const data = {
      ...selectedCandidate,
      ...updatedData,
    };
    if (!data.hasOwnProperty('createdRole')) {
      data.createdRole = user?.role;
    }
    if (file !== null) {
      const storageRef = ref(storage, `files/${file.name}`);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);
      await addDoc(collection(db, "files"), { name: file.name, url });
      data.url = url;
      data.resume = file.name;
    }
    try {
      if (data?.file) delete data.file;
      // const file = updatedData?.resume;
      await updateCandidates(selectedCandidate.id, data); // Update the candidate data
      setEditPopupOpen(false); // Close the popup
      setLoader(false);
      setSelectedCandidate(null); // Clear the selected candidate
    } catch (error) {
      alert("Error updating candidate:", JSON.stringify(error));
    }
  };

  /**
   * Handles the submission of the add profile form.
   * @param {Object} newProfileData - The new profile data to add.
   */
  const handleAddProfileSubmit = async (newProfileData) => {
    setLoader(true); // Show loader while processing
    const data = {
      ...newProfileData, // Use the new profile data submitted
    };
    // Handle file upload if a file is attached
    if (file !== null) {
      const storageRef = ref(storage, `files/${file.name}`); // Reference for storage
      try {
        await uploadBytes(storageRef, file); // Upload the file to storage
        const url = await getDownloadURL(storageRef); // Get download URL
        await addDoc(collection(db, "files"), { name: file.name, url }); // Add file to Firestore
        data.url = url; // Set the URL in the candidate data
        data.resume = file.name; // Save the file name in the candidate data
      } catch (uploadError) {
        alert("Error uploading file:", JSON.stringify(uploadError)); // Handle file upload error
        setLoader(false); // Hide loader on error
        return; // Exit the function on error
      }
    }
    try {
      // Now, you may need to add the new profile data to the candidates collection.
      createCandidates(data); // Add the new profile data to the candidates collection
      setAddPopupOpen(false); // Close the popup for adding a profile
      setSelectedCandidate(null); // Clear selected candidate data
    } catch (error) {
      alert("Error adding candidate:", JSON.stringify(error)); // Handle errors during profile addition
      setLoader(false); // Ensure the loader is hidden on error
    }
  };

  /**
   * Deletes a file by its name from Firebase Storage and Firestore.
   * @param {string} fileName - The name of the file to delete.
   */
  const deleteFileByNameOutput = async (fileName) => {
    try {
      setLoader(true);
      // Delete the file from Firebase Storage
      const fileRef = ref(storage, `files/${fileName}`);
      await deleteObject(fileRef);
      // Delete the file metadata from Firestore
      const q = query(collection(db, "files"), where("name", "==", fileName));
      const querySnapshot = await getDocs(q);

      // Use a for...of loop to handle async delete operations
      for (const doc of querySnapshot?.docs) {
        await deleteDoc(doc.ref); // Await the deletion of each document
      }
      updateCandidates(selectedCandidate.id, {
        ...selectedCandidate,
        resume: null,
        url: null,
      });
      setEditPopupOpen(false);
      setSelectedCandidate({ ...selectedCandidate, resume: null, url: null });
      setCandidatesData([]);

      setLoader(false);

      fetchFiles();
    } catch (error) {
      setLoader(false);
      console.error("Error deleting file:", error);
    }
  };
  const handleFileChangeOutput = (e) => {
    setFile(e.target.files[0]);
  };

  const fetchResources = async () => {
    const querySnapshot = await getDocs(collection(db, "BDResources"));
    const resourcesList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setResources(resourcesList);
  };
  /**
   * Deletes a resume from Firebase Storage and Firestore.
   * @param {string} id - The ID of the resume to delete.
   */
  const deleteResumeInBD = async (id) => {
    try {
      // Delete the resume file from Firebase Storage
      const fileRef = ref(storage, `files/${id}`);
      await deleteObject(fileRef);
      // Delete the resume file metadata from Firestore
      const q = query(collection(db, "files"), where("name", "==", id));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });
      fetchFiles();
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  /**
   * Filters candidates based on the input value.
   * @param {Event} e - The input event from the search field.
   */
  const filterCandidates = (e) => {
    setCandidatesData([]);
    const value = e.target.value.trim(); // Get input value and trim whitespace
    if (value !== "") {
      const filteredCandidates = tempCandidatesData.filter((candidate) => {
        const fullName = candidate.fullName.trim(); // Trim whitespace from candidate fullName
        return fullName.toLowerCase().includes(value.toLowerCase()); // Compare ignoring case and whitespace
      });
      setCandidatesData(JSON.parse(JSON.stringify(filteredCandidates))); // Set the filtered candidates data
    } else {
      setCandidatesData(JSON.parse(JSON.stringify(tempCandidatesData))); // Set the original candidates data if input is empty
    }
  };

  /**
   * Filters candidates based on the current filter state.
   * @param {Array} candidates - The list of candidates to filter.
   * @param {string} filter - The current filter state.
   * @returns {Array} - The filtered candidates.
   */
  const filterCandidatesLogic = (candidates, filter) => {
    return candidates.filter((candidate) => {
      if (filter === "selected") {
        return (
          (candidate.isSelected || !candidate.isSelected) &&
          !candidate.isOffered &&
          !candidate.isPayed
        );
      }
      if (filter === "offered") {
        return (
          candidate.isSelected && candidate.isOffered && !candidate.isPayed
        );
      }
      if (filter === "payed") {
        return candidate.isSelected && candidate.isOffered && candidate.isPayed;
      }
      return false;
    });
  };

  return (
    <>
      {loader && <Loader isLoading={loader} />}
      {isEditPopupOpen && (
        <EditPopup
          isAccentureTeam={isAccentureTeam()}
          isSuperAdmin={isSuperAdmin()}
          candidate={selectedCandidate}
          deleteResume={deleteResumeInBD}
          onClose={() => setEditPopupOpen(false)}
          onSubmit={handleEditSubmit}
          deleteFileByName={deleteFileByNameOutput}
          handleFileChange={handleFileChangeOutput}
        />
      )}
      {isAddPopupOpen && (
        <AddPopup
          isSuperAdmin={isSuperAdmin()}
          isAccentureTeam={isAccentureTeam()}
          candidate={{}}
          onClose={() => setAddPopupOpen(false)}
          onSubmit={handleAddProfileSubmit}
          handleFileChange={handleFileChangeOutput}
        />
      )}
      {/* <div>
        <input type="file" ref={fileRef} onChange={handleFileChange} />
        <button onClick={uploadFile}>Upload File</button>
    </div> */}
      <div className="db-container">
        <div
          className="db-header-container"
          style={{
            backgroundColor: "#0071bd",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <div className="flex justify-between items-center w-full">
            <h3 className="dashboard-title">
              Candidates List &nbsp;&nbsp;&nbsp;
              <span
                className="add-icon float-right"
                onClick={setAddPopupOpenFunc}
              >
                <AddIcon />
              </span>
              <LogoutIcon
                className="logout-icon float-right"
                onClick={logOut}
              />
            </h3>
            <FilterButtons filter={filter} setFilter={setFilter} />
          </div>
        </div>
        <br />
        {/* <SchedulesSelection
          resources={resources}
          candidatesData={candidatesData}
          exportAsExcelFile={exportAsExcelFile}
          filterCandidates={filterCandidates}
        /> */}
        <CandidateSearchExport 
          candidatesData={candidatesData}
          exportAsExcelFile={exportAsExcelFile}
          filterCandidates={filterCandidates}
        />
        <br />
        <>
          <ConfirmationPopup
            show={showDeletePopup}
            onClose={handleCloseDeletePopup}
            onConfirm={handleConfirmDelete}
          />
        </>
        <div className="card-container">
          {filterCandidatesLogic(candidatesData, filter).map(
            (candidate, index) => (
              <CandidateCard
                key={index}
                index={index}
                candidate={candidate}
                isAccentureTeam={isAccentureTeam()}
                isSuperAdmin={isSuperAdmin}
                formatAmount={formatAmount}
                deleteResume={deleteResumeInBD}
                downloadResumeByUrl={downloadResumeByUrl}
                onEdit={openEditPopup}
                onDelete={handleDeleteClick}
              />
            )
          )}
          {filterCandidatesLogic(candidatesData, filter).length === 0 && (
            <h4 className="text-center">No Data Found</h4>
          )}
        </div>

        <div className="card-container-responsive">
          {filterCandidatesLogic(candidatesData, filter).map(
            (candidate, index) => (
              <CandidateCard
                key={index}
                {...{
                  candidate,
                  isSuperAdmin,
                  isAccentureTeam,
                  formatAmount,
                  deleteResume: deleteResumeInBD,
                  downloadResumeByUrl,
                  onEdit: () => openEditPopup(candidate),
                  onDelete: () => handleDeleteClick(index),
                }}
              />
            )
          )}
          {filterCandidatesLogic(candidatesData, filter).length === 0 && (
            <h4 className="text-center">No Data Found</h4>
          )}
        </div>
      </div>
    </>
  );
}
export default Dashboard;
