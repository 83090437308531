import '../css/Footer.css'

const Footer = () => {
    return (
        <footer style={{backgroundColor: '#0071bd !important',color: 'white !important'}} className='footer'>
            © 2025 All Rights Reserved GSR Sagar
        </footer>
    )
};

export default Footer;