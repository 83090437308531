import React from 'react';  

export const ExcelIcon = () => {  
  return (  
    <svg  
      viewBox="0 0 32 32"  
      height="18px"  
      width="18px"  
      fill="#000000"  
    >  
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>  
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>  
      <g id="SVGRepo_iconCarrier">  
        <defs>  
          <linearGradient  
            id="a"  
            x1="4.494"  
            y1="-2092.086"  
            x2="13.832"  
            y2="-2075.914"  
            gradientTransform="translate(0 2100)"  
            gradientUnits="userSpaceOnUse"  
          >  
            <stop offset="0" stopColor="#18884f"></stop>  
            <stop offset="0.5" stopColor="#117e43"></stop>  
            <stop offset="1" stopColor="#0b6631"></stop>  
          </linearGradient>  
        </defs>  
        <title>file_type_excel</title>  
        <path  
          d="M19.581,15.35,8.512,13.4V27.809A1.192,1.192,0,0,0,9.705,29h19.1A1.192,1.192,0,0,0,30,27.809h0V22.5Z"  
          style={{ fill: "#185c37" }}  
        ></path>  
        <path  
          d="M19.581,3H9.705A1.192,1.192,0,0,0,8.512,4.191h0V9.5L19.581,16l5.861,1.95L30,16V9.5Z"  
          style={{ fill: "#21a366" }}  
        ></path>  
        <path  
          d="M8.512,9.5H19.581V16H8.512Z"  
          style={{ fill: "#107c41" }}  
        ></path>  
        <path  
          d="M16.434,8.2H8.512V24.45h7.922a1.2,1.2,0,0,0,1.194-1.191V9.391A1.2,1.2,0,0,0,16.434,8.2Z"  
          style={{ opacity: 0.1, isolation: 'isolate' }}  
        ></path>  
        <path  
          d="M15.783,8.85H8.512V25.1h7.271a1.2,1.2,0,0,0,1.194-1.191V10.041A1.2,1.2,0,0,0,15.783,8.85Z"  
          style={{ opacity: 0.2, isolation: 'isolate' }}  
        ></path>  
        <path  
          d="M15.783,8.85H8.512V23.8h7.271a1.2,1.2,0,0,0,1.194-1.191V10.041A1.2,1.2,0,0,0,15.783,8.85Z"  
          style={{ opacity: 0.2, isolation: 'isolate' }}  
        ></path>  
        <path  
          d="M15.132,8.85H8.512V23.8h6.62a1.2,1.2,0,0,0,1.194-1.191V10.041A1.2,1.2,0,0,0,15.132,8.85Z"  
          style={{ opacity: 0.2, isolation: 'isolate' }}  
        ></path>  
        <path  
          d="M3.194,8.85H15.132a1.193,1.193,0,0,1,1.194,1.191V21.959a1.193,1.193,0,0,1-1.194,1.191H3.194A1.192,1.192,0,0,1,2,21.959V10.041A1.192,1.192,0,0,1,3.194,8.85Z"  
          style={{ fill: 'url(#a)' }}  
        ></path>  
        <path  
          d="M5.7,19.873l2.511-3.884-2.3-3.862H7.758L9.013,14.6c.116.234.2.408.238.524h.017c.082-.188.169-.369.26-.546l1.342-2.447h1.7l-2.359,3.84,2.419,3.905H10.821l-1.45-2.711A2.355,2.355,0,0,1,9.2,16.8H9.176a1.688,1.688,0,0,1-.168.351L7.515,19.873Z"  
          style={{ fill: "#fff" }}  
        ></path>  
        <path  
          d="M28.806,3H19.581V9.5H30V4.191A1.192,1.192,0,0,0,28.806,3Z"  
          style={{ fill: "#33c481" }}  
        ></path>  
        <path  
          d="M19.581,16H30v6.5H19.581Z"  
          style={{ fill: "#107c41" }}  
        ></path>  
      </g>  
    </svg>  
  );  
};  

export const GreenDeleteIcon = () => {  
  return (  
    <svg  
      viewBox="0 0 24 24"  
      fill="none"  
      width="18px"  
      height="18px"  
    >  
      <path  
        opacity="0.5"  
        d="M22 16.0003V15.0003C22 12.1718 21.9998 10.7581 21.1211 9.8794C20.2424 9.00072 18.8282 9.00072 15.9998 9.00072H7.99977C5.17135 9.00072 3.75713 9.00072 2.87845 9.8794C2 10.7579 2 12.1711 2 14.9981V15.0003V16.0003C2 18.8287 2 20.2429 2.87868 21.1216C3.75736 22.0003 5.17157 22.0003 8 22.0003H16H16C18.8284 22.0003 20.2426 22.0003 21.1213 21.1216C22 20.2429 22 18.8287 22 16.0003Z"  
        fill="#097969"  
      />  
      <path  
        fillRule="evenodd"  
        clipRule="evenodd"  
        d="M12 1.25C11.5858 1.25 11.25 1.58579 11.25 2L11.25 12.9726L9.56943 11.0119C9.29986 10.6974 8.82639 10.661 8.51189 10.9306C8.1974 11.2001 8.16098 11.6736 8.43054 11.9881L11.4305 15.4881C11.573 15.6543 11.781 15.75 12 15.75C12.2189 15.75 12.4269 15.6543 12.5694 15.4881L15.5694 11.9881C15.839 11.6736 15.8026 11.2001 15.4881 10.9306C15.1736 10.661 14.7001 10.6974 14.4305 11.0119L12.75 12.9726L12.75 2C12.75 1.58579 12.4142 1.25 12 1.25Z"  
        fill="#097969"  
      />  
    </svg>  
  );  
};  


export const PdfIcon = () => (
  <svg
    height="24px"
    width="24px"
    version="1.1"
    id="Layer_1"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    fill="#000000"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path
        style={{ fill: "#E2E5E7" }}
        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
      />
      <path
        style={{ fill: "#B0B7BD" }}
        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
      />
      <polygon style={{ fill: "#CAD1D8" }} points="480,224 384,128 480,128 " />
      <path
        style={{ fill: "#F15642" }}
        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16 V416z"
      />
      <g>
        <path
          style={{ fill: "#FFFFFF" }}
          d="M101.744,303.152c0-4.224,3.328-8.832,8.688-8.832h29.552c16.64,0,31.616,11.136,31.616,32.48 c0,20.224-14.976,31.488-31.616,31.488h-21.36v16.896c0,5.632-3.584,8.816-8.192,8.816c-4.224,0-8.688-3.184-8.688-8.816V303.152z M118.624,310.432v31.872h21.36c8.576,0,15.36-7.568,15.36-15.504c0-8.944-6.784-16.368-15.36-16.368H118.624z"
        />
        <path
          style={{ fill: "#FFFFFF" }}
          d="M196.656,384c-4.224,0-8.832-2.304-8.832-7.92v-72.672c0-4.592,4.608-7.936,8.832-7.936h29.296 c58.464,0,57.184,88.528,1.152,88.528H196.656z M204.72,311.088V368.4h21.232c34.544,0,36.08-57.312,0-57.312H204.72z"
        />
        <path
          style={{ fill: "#FFFFFF" }}
          d="M303.872,312.112v20.336h32.624c4.608,0,9.216,4.608,9.216,9.072c0,4.224-4.608,7.68-9.216,7.68 h-32.624v26.864c0,4.48-3.184,7.92-7.664,7.92c-5.632,0-9.072-3.44-9.072-7.92v-72.672c0-4.592,3.456-7.936,9.072-7.936h44.912 c5.632,0,8.96,3.344,8.96,7.936c0,4.096-3.328,8.704-8.96,8.704h-37.248V312.112z"
        />
      </g>
      <path
        style={{ fill: "#CAD1D8" }}
        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
      />
    </g>
  </svg>
);

// ... existing code ...
export const CurrencyIcon = () => (
  <svg
    height="30px"
    width="30px"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    fill="#000000"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <circle
        style={{ fill: "#324A5E" }}
        cx="256"
        cy="256"
        r="256"
      ></circle>
      <path
        style={{ fill: "#2B3B4E" }}
        d="M510.376,284.815L365.011,139.45l-108.285,21.583l-115.884,4.486l35.323,35.323l-37.376,19.373 l55.236,55.236l-12.438,12.441l93.077,125.285l79.367,79.367C438.294,457.581,499.834,378.914,510.376,284.815z"
      ></path>
      <path
        style={{ fill: "#FFD15D" }}
        d="M352.108,190.923h-41.041c-1.838-7.38-4.73-14.348-8.576-20.687h49.617 c9.997,0,18.101-8.104,18.101-18.101s-8.104-18.101-18.101-18.101H152.997c-9.997,0-18.101,8.104-18.101,18.101 s8.104,18.101,18.101,18.101h85.333c14.853,0,27.776,8.395,34.29,20.687H152.997c-9.997,0-18.101,8.104-18.101,18.101 c0,9.997,8.104,18.101,18.101,18.101H272.62c-6.515,12.291-19.437,20.687-34.29,20.687h-85.333c-7.321,0-13.921,4.411-16.724,11.173 c-2.801,6.765-1.253,14.55,3.924,19.727l142.222,142.222c3.536,3.534,8.166,5.301,12.8,5.301c4.632,0,9.264-1.767,12.8-5.301 c7.07-7.07,7.07-18.53,0-25.6L196.698,284.013h41.632c35.109,0,64.648-24.257,72.768-56.889h41.01 c9.997,0,18.101-8.104,18.101-18.101C370.209,199.027,362.105,190.923,352.108,190.923z"
      ></path>
      <g>
        <path
          style={{ fill: "#F9B54C" }}
          d="M282.419,420.935c3.536,3.534,8.166,5.301,12.8,5.301c4.632,0,9.264-1.767,12.8-5.301 c7.07-7.07,7.07-18.53,0-25.6l-69.832-69.832v51.198L282.419,420.935z"
        ></path>
        <path
          style={{ fill: "#F9B54C" }}
          d="M352.108,190.923h-41.041c-1.838-7.38-4.73-14.348-8.576-20.687h49.617 c9.997,0,18.101-8.104,18.101-18.101s-8.104-18.101-18.101-18.101H238.187v36.202h0.143c14.853,0,27.776,8.395,34.29,20.687 h-34.433v36.202h34.433c-6.515,12.291-19.437,20.687-34.29,20.687h-0.143v36.202h0.143c35.109,0,64.648-24.257,72.768-56.889h41.01 c9.997,0,18.101-8.104,18.101-18.101C370.209,199.027,362.105,190.923,352.108,190.923z"
        ></path>
      </g>
    </g>
  </svg>
);
export const DeleteIcon = () => {  
  return (  
    <svg  
    width={'25px'}
    height={'25px'}
      viewBox="0 0 24 24"  
      fill="none"  
      xmlns="http://www.w3.org/2000/svg"  
    >  
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>  
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>  
      <g id="SVGRepo_iconCarrier">  
        <path  
          d="M2.75 6.16667C2.75 5.70644 3.09538 5.33335 3.52143 5.33335L6.18567 5.3329C6.71502 5.31841 7.18202 4.95482 7.36214 4.41691C7.36688 4.40277 7.37232 4.38532 7.39185 4.32203L7.50665 3.94993C7.5769 3.72179 7.6381 3.52303 7.72375 3.34536C8.06209 2.64349 8.68808 2.1561 9.41147 2.03132C9.59457 1.99973 9.78848 1.99987 10.0111 2.00002H13.4891C13.7117 1.99987 13.9056 1.99973 14.0887 2.03132C14.8121 2.1561 15.4381 2.64349 15.7764 3.34536C15.8621 3.52303 15.9233 3.72179 15.9935 3.94993L16.1083 4.32203C16.1279 4.38532 16.1333 4.40277 16.138 4.41691C16.3182 4.95482 16.8778 5.31886 17.4071 5.33335H19.9786C20.4046 5.33335 20.75 5.70644 20.75 6.16667C20.75 6.62691 20.4046 7 19.9786 7H3.52143C3.09538 7 2.75 6.62691 2.75 6.16667Z"  
          fill="#0071bd"  
        />  
        <path  
          opacity="0.5"  
          d="M11.6068 21.9998H12.3937C15.1012 21.9998 16.4549 21.9998 17.3351 21.1366C18.2153 20.2734 18.3054 18.8575 18.4855 16.0256L18.745 11.945C18.8427 10.4085 18.8916 9.6402 18.45 9.15335C18.0084 8.6665 17.2628 8.6665 15.7714 8.6665H8.22905C6.73771 8.6665 5.99204 8.6665 5.55047 9.15335C5.10891 9.6402 5.15777 10.4085 5.25549 11.945L5.515 16.0256C5.6951 18.8575 5.78515 20.2734 6.66534 21.1366C7.54553 21.9998 8.89927 21.9998 11.6068 21.9998Z"  
          fill="#0071bd"  
        />  
      </g>  
    </svg>  
  );  
};

export const EditIcon = () => {  
  return (  
    <svg 
    height={'25px'}
    width={'25px'}
    viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">  
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>  
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>  
      <g id="SVGRepo_iconCarrier">  
        <path  
          opacity="0.4"  
          d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13"  
          stroke="#0071bd"  
          strokeWidth="1.5"  
          strokeLinecap="round"  
          strokeLinejoin="round"  
        ></path>  
        <path  
          d="M16.0399 3.01976L8.15988 10.8998C7.85988 11.1998 7.55988 11.7898 7.49988 12.2198L7.06988 15.2298C6.90988 16.3198 7.67988 17.0798 8.76988 16.9298L11.7799 16.4998C12.1999 16.4398 12.7899 16.1398 13.0999 15.8398L20.9799 7.95976C22.3399 6.59976 22.9799 5.01976 20.9799 3.01976C18.9799 1.01976 17.3999 1.65976 16.0399 3.01976Z"  
          stroke="#0071bd"  
          strokeWidth="1.5"  
          strokeMiterlimit="10"  
          strokeLinecap="round"  
          strokeLinejoin="round"  
        ></path>  
        <path  
          opacity="0.4"  
          d="M14.9102 4.1499C15.5802 6.5399 17.4502 8.4099 19.8502 9.0899"  
          stroke="#0071bd"  
          strokeWidth="1.5"  
          strokeMiterlimit="10"  
          strokeLinecap="round"  
          strokeLinejoin="round"  
        ></path>  
      </g>  
    </svg>  
  );  
};

export const CallIcon = () => { 
  return (
    <svg viewBox="0 0 24 24" fill="none" height={'25px'} width={'25px'} ><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fillRule="evenodd" clipRule="evenodd" d="M17.3545 22.2323C15.3344 21.7262 11.1989 20.2993 7.44976 16.5502C3.70065 12.8011 2.2738 8.66559 1.76767 6.6455C1.47681 5.48459 2.00058 4.36434 2.88869 3.72997L5.21694 2.06693C6.57922 1.09388 8.47432 1.42407 9.42724 2.80051L10.893 4.91776C11.5152 5.8165 11.3006 7.0483 10.4111 7.68365L9.24234 8.51849C9.41923 9.1951 9.96939 10.5846 11.6924 12.3076C13.4154 14.0306 14.8049 14.5807 15.4815 14.7576L16.3163 13.5888C16.9517 12.6994 18.1835 12.4847 19.0822 13.1069L21.1995 14.5727C22.5759 15.5257 22.9061 17.4207 21.933 18.783L20.27 21.1113C19.6356 21.9994 18.5154 22.5232 17.3545 22.2323ZM8.86397 15.136C12.2734 18.5454 16.0358 19.8401 17.8405 20.2923C18.1043 20.3583 18.4232 20.2558 18.6425 19.9488L20.3056 17.6205C20.6299 17.1665 20.5199 16.5348 20.061 16.2171L17.9438 14.7513L17.0479 16.0056C16.6818 16.5182 16.0047 16.9202 15.2163 16.7501C14.2323 16.5378 12.4133 15.8569 10.2782 13.7218C8.1431 11.5867 7.46219 9.7677 7.24987 8.7837C7.07977 7.9953 7.48181 7.31821 7.99439 6.95208L9.24864 6.05618L7.78285 3.93893C7.46521 3.48011 6.83351 3.37005 6.37942 3.6944L4.05117 5.35744C3.74413 5.57675 3.64162 5.89565 3.70771 6.15943C4.15989 7.96418 5.45459 11.7266 8.86397 15.136Z" fill="#0071bd"></path> </g></svg>
  )
 }

 export const AddIcon =()=>{
  return (
    <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" stroke=""><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect width="24" height="24" fill="white"></rect> <path fillRule="evenodd" clipRule="evenodd" d="M13 9C13 8.44772 12.5523 8 12 8C11.4477 8 11 8.44772 11 9V11H9C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13H11V15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15V13H15C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11H13V9ZM7.25007 2.38782C8.54878 2.0992 10.1243 2 12 2C13.8757 2 15.4512 2.0992 16.7499 2.38782C18.06 2.67897 19.1488 3.176 19.9864 4.01358C20.824 4.85116 21.321 5.94002 21.6122 7.25007C21.9008 8.54878 22 10.1243 22 12C22 13.8757 21.9008 15.4512 21.6122 16.7499C21.321 18.06 20.824 19.1488 19.9864 19.9864C19.1488 20.824 18.06 21.321 16.7499 21.6122C15.4512 21.9008 13.8757 22 12 22C10.1243 22 8.54878 21.9008 7.25007 21.6122C5.94002 21.321 4.85116 20.824 4.01358 19.9864C3.176 19.1488 2.67897 18.06 2.38782 16.7499C2.0992 15.4512 2 13.8757 2 12C2 10.1243 2.0992 8.54878 2.38782 7.25007C2.67897 5.94002 3.176 4.85116 4.01358 4.01358C4.85116 3.176 5.94002 2.67897 7.25007 2.38782Z" fill="#0071bd"></path> </g></svg>
  )
 }
// ... existing code ...
