import React from 'react';
import { ExcelIcon, GreenDeleteIcon } from './Icons';

const CandidateSearchExport = ({ candidatesData, exportAsExcelFile, filterCandidates }) => {
  return (
    <div className="row">
      <div className="flex-box-sidebyside">
        <input
          onKeyUp={filterCandidates}
          type="search"
          style={{ height: "80px !important", flex: 1 }}
          placeholder="search candidate"
        />
        <button 
          disabled={candidatesData.length === 0}
          style={{ verticalAlign: 'middle', height: "80px !important" }}
          onClick={() => exportAsExcelFile(candidatesData, "candidatesList")}
          className="ml-4 border-green"
        >
          <ExcelIcon /> &nbsp;&nbsp;
          <GreenDeleteIcon />
        </button>
      </div>
    </div>
  );
};

export default CandidateSearchExport;
