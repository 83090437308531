import React from 'react';
import { CurrencyIcon, DeleteIcon, CallIcon, PdfIcon, ExcelIcon, GreenDeleteIcon, EditIcon } from './Icons';
import '../css/Dashboard.css';

const CandidateCard = ({ key, index, candidate, onEdit, onDelete, downloadResumeByUrl, isSuperAdmin, isAccentureTeam, formatAmount }) => {
    const isAdmin = typeof isSuperAdmin === 'function' ? isSuperAdmin() : false;
    const isAccTeam = typeof isAccentureTeam === 'function' ? isAccentureTeam() : false;

    return (
        <div className="candidate-card">
            <h3 className="card-title" style={{ color: "#044570", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <b>{candidate?.fullName}</b>
                <span style={{ display: 'flex', gap: '10px' }}>
                    <span onClick={() => { onDelete(candidate.id); }} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                        <DeleteIcon />
                    </span>
                    <span onClick={() => { onEdit(index); }} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                        <EditIcon />
                    </span>
                </span>
            </h3>
            <hr />
            <p style={{ color: "gray", fontSize: "0.9rem" }}>
                <span className="technology-info">{candidate.technology}</span>
                &nbsp;&nbsp;&nbsp;
                <span className="exp-info"><b>{candidate.experience}</b></span>
                &nbsp;&nbsp;&nbsp;
                {candidate?.isSelected && <span>{/* Selected indicator */}</span>}
                &nbsp;
                {!candidate?.isSelected && candidate?.interviewProgress !== null && (
                    <span className="interview-progress">{candidate?.interviewProgress}</span>
                )}
            </p>
            {isAccTeam && (
                <>

                    <p>
                        <span style={{ fontWeight: "600", backgroundColor: "#0071bd", padding: "0.5rem", borderRadius: "5px" }}>{candidate?.emailId}</span>
                    </p>
                    <p className="handled-by">
                        <span>
                            <b style={{ fontSize: "0.7rem" }}>Candidate Email Pass:  </b>&nbsp;&nbsp;
                            <span className="handledby-info">{candidate?.candidateEmailPassword}</span>
                        </span>
                    </p>
                    <p className="handled-by">
                        <span>
                            <b style={{ fontSize: "0.7rem" }}>Candidate Id:  </b>&nbsp;&nbsp;
                            <span className="handledby-info">{candidate?.cid}</span>
                        </span>
                    </p>

                    <p className="handled-by">
                        <span>
                            <b style={{ fontSize: "0.7rem" }}>Previous Company:  </b>&nbsp;&nbsp;
                            <span className="handledby-info">{candidate?.previousCompanyName}</span>
                        </span>
                    </p>
                    <p className="handled-by"   >
                        <span>
                            <b style={{ fontSize: "0.7rem" }}>Current Company:  </b>&nbsp;&nbsp;
                            <span className="handledby-info">{candidate?.currentCompanyName}</span>
                        </span>
                    </p>
                    <p className="handled-by">
                        <span>
                            <b style={{ fontSize: "0.7rem" }}>Current CTC:  </b>&nbsp;&nbsp;
                            <span className="handledby-info">{candidate?.currentCTC}</span>
                        </span>
                    </p>
                    <p className="handled-by">
                        <span>
                            <b style={{ fontSize: "0.7rem" }}>Relevant Experience:  </b>&nbsp;&nbsp;
                            <span className="handledby-info">{candidate?.relevantExperience}</span>
                        </span>
                    </p>
                    <p className="handled-by">
                        <span>
                            <b style={{ fontSize: "0.7rem" }}>Notice Period:  </b>&nbsp;&nbsp;
                            <span className="handledby-info">{candidate?.noticePeriod}</span>
                        </span>
                    </p>
                    <p className="handled-by">
                        <span>
                            <b style={{ fontSize: "0.7rem" }}>Current Location:  </b>&nbsp;&nbsp;
                            <span className="handledby-info">{candidate?.currentLocation}</span>
                        </span>
                    </p>
                    <p className="handled-by">
                        <span>
                            <b style={{ fontSize: "0.7rem" }}>Preferred Location:  </b>&nbsp;&nbsp;
                            <span className="handledby-info">{candidate?.prefferedLocation}</span>
                        </span>
                    </p>
                    <p className="handled-by">
                        <span>
                            <b style={{ fontSize: "0.7rem" }}>1st Round Panel Name:  </b>&nbsp;&nbsp;
                            <span className="handledby-info">{candidate?.firstRoundPanelName}</span>
                        </span>
                    </p>
                    <p className="handled-by">
                        <span>
                            <b style={{ fontSize: "0.7rem" }}>2nd Round Panel Name:  </b>&nbsp;&nbsp;
                            <span className="handledby-info">{candidate?.secondRoundPanelName}</span>
                        </span>
                    </p>
                </>
            )}
            <p>
                <span className="contact-info">
                    <CallIcon /> &nbsp;&nbsp;
                    <b><a href={`tel:${candidate.contact}`}>{candidate.contact}</a></b>
                </span>
                &nbsp;&nbsp;&nbsp;
                <span className="selected-info">{candidate.isOffered ? "Offered" : "Not Offered"}</span>
            </p>
            {candidate.handledBy &&
                <p className="handled-by">
                    <span>
                        <b style={{ fontSize: "0.7rem" }}>Handled By : </b>&nbsp;&nbsp;
                        <span className="handledby-info">{candidate.handledBy}</span>
                    </span>
                </p>
            }
            {candidate.vendorName &&
                <p className="handled-by">
                    <span>
                        <b style={{ fontSize: "0.7rem" }}>Vendor Name : </b>&nbsp;&nbsp;
                        <span className="handledby-info">{candidate.vendorName}</span>
                    </span>
                </p>
            }
            {candidate.referenceName &&
                <p className="handled-by">
                    <span>
                        <b style={{ fontSize: "0.7rem" }}>Reference Name : </b>&nbsp;&nbsp;
                        <span className="handledby-info">{candidate.referenceName}</span>
                    </span>
                </p>
            }
            {candidate?.resume && candidate?.url && (
                <p className="resume-download" onClick={() => downloadResumeByUrl(candidate.url)}>
                    <PdfIcon />
                    &nbsp;&nbsp;&nbsp;
                    <span style={{ textDecoration: "underline", cursor: "pointer" }}>{candidate?.resume}</span>
                </p>
            )}
            {/* Additional payment info */}
            <hr />
            {
                !isAccTeam &&
                <>
                    <p>
                        {isAdmin && (
                            <span className="payment-info">
                                <CurrencyIcon /> &nbsp;&nbsp;
                                {!candidate.isPayed
                                    ? `Balance : ${formatAmount(candidate.balance)}/-`
                                    : `Completed (${formatAmount(candidate.paidAmount)})/-`}
                            </span>
                        )}
                    </p>
                    <p>
                        {!isAdmin && (
                            <span className="payment-info">
                                <CurrencyIcon /> &nbsp;&nbsp;
                                {!candidate.isPayed
                                    ? `Balance` : `Completed`}

                            </span>
                        )}
                    </p></>
            }
        </div>
    );
};

export default CandidateCard;
