// ... existing code ...
import { useState } from "react";
import "../css/EditPopup.css";
import * as XLSX from "xlsx-js-style";

const EditPopup = ({
  isSuperAdmin,
  isAccentureTeam,
  candidate,
  onClose,
  onSubmit,
  handleFileChange,
  deleteResume,
  deleteFileByName,
}) => {
  const [formData, setFormData] = useState(candidate);
  const [fileName, setFileName] = useState("");

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(formData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "candidate.xlsx");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const handleLocalFileChange = (e) => {
    handleFileChange(e);
  };
  const handleFileChangeInput = (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    handleLocalFileChange(e);
  };

  return (
    <div className="edit-popup-overlay" onClick={onClose}>
      <div
        className={`edit-popup ${isAccentureTeam ? "mr-25" : ""}`}
        onClick={(e) => e.stopPropagation()}
      >
        <h2>Edit Candidate</h2>
        <form onSubmit={handleSubmit} className="edit-popup-form">
          <div className="form-group">
            <div className="form-row row">
              <div className="form-column col">
                <label htmlFor="fullName">Full Name</label>
                <input
                  type="text"
                  id="fullName"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  placeholder="Full Name"
                  required
                />
              </div>
              <div className="form-column col">
                <label htmlFor="emailId">Email Id</label>
                <input
                  type="email"
                  name="emailId"
                  id="emailId"
                  value={formData.emailId}
                  onChange={handleChange}
                  placeholder="Email ID"
                  required
                />
              </div>
              <div className="form-column col">
                <label htmlFor="emailId">Contact Number</label>
                <input
                  type="text"
                  name="contact"
                  id="contact"
                  value={formData.contact}
                  onChange={handleChange}
                  placeholder="Mobile NO"
                  required
                />
              </div>
            </div>
            <div className="form-row row">
              <label htmlFor="emailId">Contact Number</label>
              <input
                type="text"
                name="contact"
                id="contact"
                value={formData.contact}
                onChange={handleChange}
                placeholder="Mobile NO"
                required
              />
            </div>
            <div className="form-row row">
              <div className="form-column col">
                <label htmlFor="technology">Technology</label>
                <input
                  id="technology"
                  list="technology-options"
                  name="technology"
                  value={formData.technology}
                  onChange={handleChange}
                  placeholder="Technology"
                  required
                />
                <datalist id="technology-options">
                  {" "}
                  // Added this block
                  <option value="DotNet Full Stack" />
                  <option value="Java Full Stack" />
                  <option value="Angular" />
                  <option value="Reactjs" />
                  <option value="Testing" />
                </datalist>
                <br />
                <div>
                  <label htmlFor="resume">IsPayed</label>
                  <input
                    type="checkbox"
                    name="isPayed"
                    checked={formData.isPayed}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="resume">IsOffered</label>
                  <input
                    type="checkbox"
                    name="isOffered"
                    checked={formData.isOffered}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label htmlFor="resume">IsSelected</label>
                  <input
                    type="checkbox"
                    name="isSelected"
                    checked={formData.isSelected}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-column col">
                <div className="form-row row">
                  <div className="form-column col">
                    <label htmlFor="experience">Experience</label>
                    <input
                      type="text"
                      id="experience"
                      name="experience"
                      list="experience-options" // Added this line
                      value={formData.experience}
                      onChange={handleChange}
                      placeholder="Experience"
                      required
                    />
                    <datalist id="experience-options">
                      {" "}
                      // Added this block
                      <option value="2+ years" />
                      <option value="3+ years" />
                      <option value="4+ years" />
                      <option value="5+ years" />
                      <option value="6+ years" />
                      <option value="7+ years" />
                    </datalist>
                  </div>
                  {isSuperAdmin && (
                    <div className="form-column col">
                      <label htmlFor="balance">Balance</label>
                      <input
                        type="text"
                        id="balance"
                        name="balance"
                        value={formData.balance}
                        onChange={handleChange}
                        placeholder="Amount Balance"
                        required
                      />
                    </div>
                  )}
                </div>
                {isSuperAdmin && (
                  <div className="form-row row">
                    <div className="form-column col">
                      <label htmlFor="paidAmount">Paid Amount</label>
                      <input
                        type="text"
                        id="paidAmount"
                        name="paidAmount"
                        value={formData.paidAmount}
                        onChange={handleChange}
                        placeholder="Amount Paid"
                        required
                      />
                    </div>
                  </div>
                )}
                <div className="form-row row">
                  <div className="form-column col">
                    <label htmlFor="paidAmount">Interview Progress</label>
                    <input
                      list="interviewProgress-options"
                      id="interviewProgress"
                      name="interviewProgress"
                      value={formData.interviewProgress}
                      onChange={handleChange}
                      placeholder="Interview Progress"
                      required
                    />
                    <datalist id="interviewProgress-options">
                      {" "}
                      // Added this block
                      <option value="L1 Completed" />
                      <option value="Waiting for L1" />
                      <option value="L1 Completed Waiting for Update" />
                      <option value="L2 Completed" />
                      <option value="Waiting for L2" />
                      <option value="L2 Completed Waiting for Update" />
                    </datalist>
                  </div>
                </div>
                <div className="form-row row">
                  <div className="form-column col">
                    <label htmlFor="emailId">Contact Number</label>
                    <input
                      type="text"
                      name="contact"
                      id="contact"
                      value={formData.contact}
                      onChange={handleChange}
                      placeholder="Mobile NO"
                      required
                    />
                  </div>
                </div>
                <div className="form-row row">
                  <div
                    className="form-column col"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {!formData?.resume ? (
                      <label htmlFor="resume-upload" className="resume-label">
                        Upload Resume
                        <input
                          type="file"
                          name="resume"
                          id="resume-upload"
                          className="resume-input"
                          onChange={handleFileChangeInput}
                        />
                      </label>
                    ) : (
                      ""
                    )}
                    {formData.resume ? (
                      <svg
                        height="24px"
                        width="24px"
                        version="1.1"
                        id="Layer_1"
                        viewBox="0 0 512 512"
                        xmlSpace="preserve"
                        fill="#000000"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          <path
                            style={{ fill: "#E2E5E7" }}
                            d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
                          />
                          <path
                            style={{ fill: "#B0B7BD" }}
                            d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
                          />
                          <polygon
                            style={{ fill: "#CAD1D8" }}
                            points="480,224 384,128 480,128 "
                          />
                          <path
                            style={{ fill: "#F15642" }}
                            d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16 V416z"
                          />
                          <g>
                            <path
                              style={{ fill: "#FFFFFF" }}
                              d="M101.744,303.152c0-4.224,3.328-8.832,8.688-8.832h29.552c16.64,0,31.616,11.136,31.616,32.48 c0,20.224-14.976,31.488-31.616,31.488h-21.36v16.896c0,5.632-3.584,8.816-8.192,8.816c-4.224,0-8.688-3.184-8.688-8.816V303.152z M118.624,310.432v31.872h21.36c8.576,0,15.36-7.568,15.36-15.504c0-8.944-6.784-16.368-15.36-16.368H118.624z"
                            />
                            <path
                              style={{ fill: "#FFFFFF" }}
                              d="M196.656,384c-4.224,0-8.832-2.304-8.832-7.92v-72.672c0-4.592,4.608-7.936,8.832-7.936h29.296 c58.464,0,57.184,88.528,1.152,88.528H196.656z M204.72,311.088V368.4h21.232c34.544,0,36.08-57.312,0-57.312H204.72z"
                            />
                            <path
                              style={{ fill: "#FFFFFF" }}
                              d="M303.872,312.112v20.336h32.624c4.608,0,9.216,4.608,9.216,9.072c0,4.224-4.608,7.68-9.216,7.68 h-32.624v26.864c0,4.48-3.184,7.92-7.664,7.92c-5.632,0-9.072-3.44-9.072-7.92v-72.672c0-4.592,3.456-7.936,9.072-7.936h44.912 c5.632,0,8.96,3.344,8.96,7.936c0,4.096-3.328,8.704-8.96,8.704h-37.248V312.112z"
                            />
                          </g>
                          <path
                            style={{ fill: "#CAD1D8" }}
                            d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
                          />
                        </g>
                      </svg>
                    ) : (
                      ""
                    )}{" "}
                    &nbsp;&nbsp;&nbsp;
                    {formData.resume
                      ? formData.resume
                      : fileName && (
                          <span
                            className="file-name"
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {fileName}
                          </span>
                        )}{" "}
                    {/* Show the file name */}
                    {/* {
                      formData.resume ?
                      <svg onClick={deleteResume(formData.resume)} height={'30px'} width={'30px'} viewBox="0 0 32 32" enableBackground="new 0 0 32 32" version="1.1" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Home"></g> <g id="Print"></g> <g id="Mail"></g> <g id="Camera"></g> <g id="Video"></g> <g id="Film"></g> <g id="Message"></g> <g id="Telephone"></g> <g id="User"></g> <g id="File"></g> <g id="Folder"></g> <g id="Map"></g> <g id="Download"></g> <g id="Upload"></g> <g id="Video_Recorder"></g> <g id="Schedule"></g> <g id="Cart"></g> <g id="Setting"></g> <g id="Search"></g> <g id="Pencils"></g> <g id="Group"></g> <g id="Record"></g> <g id="Headphone"></g> <g id="Music_Player"></g> <g id="Sound_On"></g> <g id="Sound_Off"></g> <g id="Lock"></g> <g id="Lock_open"></g> <g id="Love"></g> <g id="Favorite"></g> <g id="Film_1_"></g> <g id="Music"></g> <g id="Puzzle"></g> <g id="Turn_Off"></g> <g id="Book"></g> <g id="Save"></g> <g id="Reload"></g> <g id="Trash"> <path d="M28,5h-5V4c0-1.7-1.3-3-3-3h-4h-4c-1.7,0-3,1.3-3,3v1H4C3.5,5,3,5.5,3,6s0.5,1,1,1h1v19c0,2.8,2.2,5,5,5h6 h6c2.8,0,5-2.2,5-5V7h1c0.5,0,1-0.5,1-1S28.5,5,28,5z" fill="#FE9803"></path> <g> <path d="M12,11.1c-0.5,0-1,0.5-1,1v12c0,0.6,0.5,1,1,1s1-0.4,1-1v-12C13,11.6,12.5,11.1,12,11.1z" fill="#3F51B5"></path> <path d="M20,11.1c-0.5,0-1,0.5-1,1v12c0,0.6,0.5,1,1,1s1-0.4,1-1v-12C21,11.6,20.5,11.1,20,11.1z" fill="#3F51B5"></path> <path d="M28,5h-5V4c0-1.7-1.3-3-3-3h-4h-4c-1.7,0-3,1.3-3,3v1H4C3.5,5,3,5.5,3,6s0.5,1,1,1h12h12c0.5,0,1-0.5,1-1 S28.5,5,28,5z" fill="#3F51B5"></path> </g> </g> <g id="Tag"></g> <g id="Link"></g> <g id="Like"></g> <g id="Bad"></g> <g id="Gallery"></g> <g id="Add"></g> <g id="Close"></g> <g id="Forward"></g> <g id="Back"></g> <g id="Buy"></g> <g id="Mac"></g> <g id="Laptop"></g> </g></svg>
                    :""} &nbsp;&nbsp;&nbsp; */}
                    {formData.resume ? (
                      <svg
                        onClick={() => deleteFileByName(formData.resume)}
                        height={"30px"}
                        width={"30px"}
                        viewBox="0 0 32 32"
                        enableBackground="new 0 0 32 32"
                        version="1.1"
                        xmlSpace="preserve"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#000000"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <g id="Home"></g> <g id="Print"></g> <g id="Mail"></g>{" "}
                          <g id="Camera"></g> <g id="Video"></g>{" "}
                          <g id="Film"></g> <g id="Message"></g>{" "}
                          <g id="Telephone"></g> <g id="User"></g>{" "}
                          <g id="File"></g> <g id="Folder"></g> <g id="Map"></g>{" "}
                          <g id="Download"></g> <g id="Upload"></g>{" "}
                          <g id="Video_Recorder"></g> <g id="Schedule"></g>{" "}
                          <g id="Cart"></g> <g id="Setting"></g>{" "}
                          <g id="Search"></g> <g id="Pencils"></g>{" "}
                          <g id="Group"></g> <g id="Record"></g>{" "}
                          <g id="Headphone"></g> <g id="Music_Player"></g>{" "}
                          <g id="Sound_On"></g> <g id="Sound_Off"></g>{" "}
                          <g id="Lock"></g> <g id="Lock_open"></g>{" "}
                          <g id="Love"></g> <g id="Favorite"></g>{" "}
                          <g id="Film_1_"></g> <g id="Music"></g>{" "}
                          <g id="Puzzle"></g> <g id="Turn_Off"></g>{" "}
                          <g id="Book"></g> <g id="Save"></g>{" "}
                          <g id="Reload"></g>{" "}
                          <g id="Trash">
                            {" "}
                            <path
                              d="M28,5h-5V4c0-1.7-1.3-3-3-3h-4h-4c-1.7,0-3,1.3-3,3v1H4C3.5,5,3,5.5,3,6s0.5,1,1,1h1v19c0,2.8,2.2,5,5,5h6 h6c2.8,0,5-2.2,5-5V7h1c0.5,0,1-0.5,1-1S28.5,5,28,5z"
                              fill="#FE9803"
                            ></path>{" "}
                            <g>
                              {" "}
                              <path
                                d="M12,11.1c-0.5,0-1,0.5-1,1v12c0,0.6,0.5,1,1,1s1-0.4,1-1v-12C13,11.6,12.5,11.1,12,11.1z"
                                fill="#3F51B5"
                              ></path>{" "}
                              <path
                                d="M20,11.1c-0.5,0-1,0.5-1,1v12c0,0.6,0.5,1,1,1s1-0.4,1-1v-12C21,11.6,20.5,11.1,20,11.1z"
                                fill="#3F51B5"
                              ></path>{" "}
                              <path
                                d="M28,5h-5V4c0-1.7-1.3-3-3-3h-4h-4c-1.7,0-3,1.3-3,3v1H4C3.5,5,3,5.5,3,6s0.5,1,1,1h12h12c0.5,0,1-0.5,1-1 S28.5,5,28,5z"
                                fill="#3F51B5"
                              ></path>{" "}
                            </g>{" "}
                          </g>{" "}
                          <g id="Tag"></g> <g id="Link"></g> <g id="Like"></g>{" "}
                          <g id="Bad"></g> <g id="Gallery"></g> <g id="Add"></g>{" "}
                          <g id="Close"></g> <g id="Forward"></g>{" "}
                          <g id="Back"></g> <g id="Buy"></g> <g id="Mac"></g>{" "}
                          <g id="Laptop"></g>{" "}
                        </g>
                      </svg>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col">
                  <button
                    className="btn btn-primary"
                    style={{ backgroundColor: "#0071bd !important" }}
                    type="submit"
                  >
                    Save
                  </button>
                </div>
                <div className="col">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <form onSubmit={handleSubmit} className="edit-popup-form-responsive">
          <div className="form-group">
            <div className="form-row row">
              <label htmlFor="fullName">Full Name</label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                placeholder="Full Name"
                required
              />
            </div>
            <div className="form-row row">
              <label htmlFor="emailId">Email Id</label>
              <input
                type="email"
                name="emailId"
                id="emailId"
                value={formData.emailId}
                onChange={handleChange}
                placeholder="Email ID"
                required
              />
            </div>
            {isAccentureTeam && (
              <div className="form-row row">
                <label htmlFor="emailId">Candidate Email Password</label>
                <input
                  type="text"
                  name="candidateEmailPassword"
                  id="candidateEmailPassword"
                  value={formData.candidateEmailPassword}
                  onChange={handleChange}
                  placeholder="Email Password"
                  required
                />
              </div>
            )}
            {isAccentureTeam && (
              <div className="form-row row">
                <label htmlFor="cid">CID</label>
                <input
                  type="text"
                  name="cid"
                  id="cid"
                  value={formData.cid}
                  onChange={handleChange}
                  placeholder="CID"
                  required
                />
              </div>
            )}
            <div className="form-row row">
              <label htmlFor="handledBy">Handled By</label>
              <input
                type="text"
                name="handledBy"
                id="handledBy"
                disabled={isAccentureTeam}
                value={formData.handledBy ?? 'ACCENTURETEAM'}
                onChange={handleChange}
                placeholder="Handled By Name"
                required
              />
            </div>
            <div className="form-row row">
              <label htmlFor="emailId">Contact Number</label>
              <input
                type="text"
                name="contact"
                id="contact"
                value={formData.contact}
                onChange={handleChange}
                placeholder="Mobile NO"
                required
              />
            </div>
            <div className="form-row row">
              <label htmlFor="technology">Technology/Skill</label>
              <input
                id="technology"
                list="technology-options"
                name="technology"
                value={formData.technology}
                onChange={handleChange}
                placeholder="Technology"
                required
              />
              <datalist id="technology-options">
                {" "}
                // Added this block
                <option value="DotNet Full Stack" />
                <option value="Java Full Stack" />
                <option value="Angular" />
                <option value="Reactjs" />
                <option value="Testing" />
              </datalist>
              <br />
              <div>
                <label htmlFor="resume">IsPayed</label>
                <input
                  type="checkbox"
                  name="isPayed"
                  checked={formData.isPayed}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="resume">IsOffered</label>
                <input
                  type="checkbox"
                  name="isOffered"
                  checked={formData.isOffered}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="resume">IsSelected</label>
                <input
                  type="checkbox"
                  name="isSelected"
                  checked={formData.isSelected}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row row">
              <label htmlFor="experience">Total Experience</label>
              <select
                id="experience"
                name="experience"
                value={formData.experience}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Select Experience
                </option>
                <option value="2+ years">2+ years</option>
                <option value="3+ years">3+ years</option>
                <option value="4+ years">4+ years</option>
                <option value="5+ years">5+ years</option>
                <option value="6+ years">6+ years</option>
                <option value="7+ years">7+ years</option>
              </select>
            </div>
            {isAccentureTeam && (
              <div className="form-row row">
                <label htmlFor="relevantExperience">Relevant Experience</label>
                <input
                  type="text"
                  name="relevantExperience"
                  id="relevantExperience"
                  value={formData.relevantExperience}
                  onChange={handleChange}
                  placeholder="Relevant Experience"
                  required
                />
              </div>
            )}

            <div className="form-row row">
              <label htmlFor="vendorName">Vendor Name</label>
              <input
                type="text"
                name="vendorName"
                id="vendorName"
                value={formData.vendorName}
                onChange={handleChange}
                placeholder="Vendor Name"
                required
              />
            </div>

            <div className="form-row row">
              <label htmlFor="referenceName">Reference Name</label>
              <input
                type="text"
                name="referenceName"
                id="referenceName"
                value={formData.referenceName}
                onChange={handleChange}
                placeholder="Reference Name"
                required
              />
            </div>

            {isAccentureTeam && (
              <div className="form-row row">
                <label htmlFor="previousCompanyName">
                  Previous Compnay Name
                </label>
                <input
                  type="text"
                  name="previousCompanyName"
                  id="previousCompanyName"
                  value={formData.previousCompanyName}
                  onChange={handleChange}
                  placeholder="Previous Company Name"
                  required
                />
              </div>
            )}
            {isAccentureTeam && (
              <div className="form-row row">
                <label htmlFor="currentCompanyName">Current Company</label>
                <input
                  type="text"
                  name="currentCompanyName"
                  id="currentCompanyName"
                  value={formData.currentCompanyName}
                  onChange={handleChange}
                  placeholder="Current Company Name"
                  required
                />
              </div>
            )}
            {isAccentureTeam && (
              <div className="form-row row">
                <label htmlFor="currentCTC">Current CTC</label>
                <input
                  type="text"
                  name="currentCTC"
                  id="currentCTC"
                  value={formData.currentCTC}
                  onChange={handleChange}
                  placeholder="Current CTC"
                  required
                />
              </div>
            )}
            {isAccentureTeam && (
              <div className="form-row row">
                <label htmlFor="noticePeriod">Notice Period</label>
                <input
                  type="text"
                  name="noticePeriod"
                  id="noticePeriod"
                  value={formData.noticePeriod}
                  onChange={handleChange}
                  placeholder="Notice Period"
                  required
                />
              </div>
            )}
            {isAccentureTeam && (
              <div className="form-row row">
                <label htmlFor="currentLocation">Current Location</label>
                <input
                  type="text"
                  name="currentLocation"
                  id="currentLocation"
                  value={formData.currentLocation}
                  onChange={handleChange}
                  placeholder="Current Location"
                  required
                />
              </div>
            )}
            {isAccentureTeam && (
              <div className="form-row row">
                <label htmlFor="prefferedLocation">Preffered Location</label>
                <input
                  type="text"
                  name="prefferedLocation"
                  id="prefferedLocation"
                  value={formData.prefferedLocation}
                  onChange={handleChange}
                  placeholder="Preffered Location"
                  required
                />
              </div>
            )}
            {isAccentureTeam && (
              <div className="form-row row">
                <label htmlFor="firstRoundPanelName">
                  1st Round Panel Name
                </label>
                <input
                  type="text"
                  name="firstRoundPanelName"
                  id="firstRoundPanelName"
                  value={formData.firstRoundPanelName}
                  onChange={handleChange}
                  placeholder="1st Round Panel Name"
                  required
                />
              </div>
            )}
            {isAccentureTeam && (
              <div className="form-row row">
                <label htmlFor="secondRoundPanelName">
                  2nd Round Panel Name
                </label>
                <input
                  type="text"
                  name="secondRoundPanelName"
                  id="secondRoundPanelName"
                  value={formData.secondRoundPanelName}
                  onChange={handleChange}
                  placeholder="2nd Round Panel Name"
                  required
                />
              </div>
            )}

            {isSuperAdmin && (
              <div className="form-row row">
                <label htmlFor="balance">Balance</label>
                <input
                  type="text"
                  id="balance"
                  name="balance"
                  value={formData.balance}
                  onChange={handleChange}
                  placeholder="Amount Balance"
                  required
                />
              </div>
            )}
            {isSuperAdmin && (
              <div className="form-row row">
                <label htmlFor="paidAmount">Paid Amount</label>
                <input
                  type="text"
                  id="paidAmount"
                  name="paidAmount"
                  value={formData.paidAmount}
                  onChange={handleChange}
                  placeholder="Amount Paid"
                  required
                />
              </div>
            )}
            <div className="form-row row">
              <div className="form-column col">
                <label htmlFor="interviewProgress">Interview Progress</label>
                <select
                  id="interviewProgress"
                  name="interviewProgress"
                  value={formData.interviewProgress}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>
                    Select Interview Progress
                  </option>
                  <option value="L1 Completed">L1 Completed</option>
                  <option value="Waiting for L1">Waiting for L1</option>
                  <option value="L1 Rejected">L1 Rejected</option>
                  <option value="L1 Completed Waiting for Update">
                    L1 Completed Waiting for Update
                  </option>
                  <option value="L2 Completed">L2 Completed</option>
                  <option value="L2 Rejected">L2 Rejected</option>
                  <option value="Waiting for L2">Waiting for L2</option>
                  <option value="L2 Completed Waiting for Update">
                    L2 Completed Waiting for Update
                  </option>
                  <option value="Interviews Completed waiting for update">
                    Interviews Completed waiting for update
                  </option>
                  <option value="Waiting for Documentaiton">
                    Waiting for Documentaiton
                  </option>
                  <option value="Documentaiton Completed Waiting for Offer">
                    Documentaiton Completed Waiting for Offer
                  </option>
                  <option value="Offered">Offered</option>
                </select>
              </div>
            </div>
            <div className="form-row row">
              <div
                className="form-column col"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {!formData?.resume ? (
                  <label htmlFor="resume-upload" className="resume-label">
                    Upload Resume
                    <input
                      type="file"
                      name="resume"
                      id="resume-upload"
                      className="resume-input"
                      onChange={handleFileChangeInput}
                    />
                  </label>
                ) : (
                  ""
                )}
                {formData.resume ? (
                  <svg
                    height="24px"
                    width="24px"
                    version="1.1"
                    id="Layer_1"
                    viewBox="0 0 512 512"
                    xmlSpace="preserve"
                    fill="#000000"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        style={{ fill: "#E2E5E7" }}
                        d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"
                      />
                      <path
                        style={{ fill: "#B0B7BD" }}
                        d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"
                      />
                      <polygon
                        style={{ fill: "#CAD1D8" }}
                        points="480,224 384,128 480,128 "
                      />
                      <path
                        style={{ fill: "#F15642" }}
                        d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16 V416z"
                      />
                      <g>
                        <path
                          style={{ fill: "#FFFFFF" }}
                          d="M101.744,303.152c0-4.224,3.328-8.832,8.688-8.832h29.552c16.64,0,31.616,11.136,31.616,32.48 c0,20.224-14.976,31.488-31.616,31.488h-21.36v16.896c0,5.632-3.584,8.816-8.192,8.816c-4.224,0-8.688-3.184-8.688-8.816V303.152z M118.624,310.432v31.872h21.36c8.576,0,15.36-7.568,15.36-15.504c0-8.944-6.784-16.368-15.36-16.368H118.624z"
                        />
                        <path
                          style={{ fill: "#FFFFFF" }}
                          d="M196.656,384c-4.224,0-8.832-2.304-8.832-7.92v-72.672c0-4.592,4.608-7.936,8.832-7.936h29.296 c58.464,0,57.184,88.528,1.152,88.528H196.656z M204.72,311.088V368.4h21.232c34.544,0,36.08-57.312,0-57.312H204.72z"
                        />
                        <path
                          style={{ fill: "#FFFFFF" }}
                          d="M303.872,312.112v20.336h32.624c4.608,0,9.216,4.608,9.216,9.072c0,4.224-4.608,7.68-9.216,7.68 h-32.624v26.864c0,4.48-3.184,7.92-7.664,7.92c-5.632,0-9.072-3.44-9.072-7.92v-72.672c0-4.592,3.456-7.936,9.072-7.936h44.912 c5.632,0,8.96,3.344,8.96,7.936c0,4.096-3.328,8.704-8.96,8.704h-37.248V312.112z"
                        />
                      </g>
                      <path
                        style={{ fill: "#CAD1D8" }}
                        d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"
                      />
                    </g>
                  </svg>
                ) : (
                  ""
                )}{" "}
                &nbsp;&nbsp;&nbsp;
                {formData.resume
                  ? formData.resume
                  : fileName && (
                      <span
                        className="file-name"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {fileName}
                      </span>
                    )}{" "}
                {/* Show the file name */}
                {/* {
                      formData.resume ?
                      <svg onClick={deleteResume(formData.resume)} height={'30px'} width={'30px'} viewBox="0 0 32 32" enableBackground="new 0 0 32 32" version="1.1" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Home"></g> <g id="Print"></g> <g id="Mail"></g> <g id="Camera"></g> <g id="Video"></g> <g id="Film"></g> <g id="Message"></g> <g id="Telephone"></g> <g id="User"></g> <g id="File"></g> <g id="Folder"></g> <g id="Map"></g> <g id="Download"></g> <g id="Upload"></g> <g id="Video_Recorder"></g> <g id="Schedule"></g> <g id="Cart"></g> <g id="Setting"></g> <g id="Search"></g> <g id="Pencils"></g> <g id="Group"></g> <g id="Record"></g> <g id="Headphone"></g> <g id="Music_Player"></g> <g id="Sound_On"></g> <g id="Sound_Off"></g> <g id="Lock"></g> <g id="Lock_open"></g> <g id="Love"></g> <g id="Favorite"></g> <g id="Film_1_"></g> <g id="Music"></g> <g id="Puzzle"></g> <g id="Turn_Off"></g> <g id="Book"></g> <g id="Save"></g> <g id="Reload"></g> <g id="Trash"> <path d="M28,5h-5V4c0-1.7-1.3-3-3-3h-4h-4c-1.7,0-3,1.3-3,3v1H4C3.5,5,3,5.5,3,6s0.5,1,1,1h1v19c0,2.8,2.2,5,5,5h6 h6c2.8,0,5-2.2,5-5V7h1c0.5,0,1-0.5,1-1S28.5,5,28,5z" fill="#FE9803"></path> <g> <path d="M12,11.1c-0.5,0-1,0.5-1,1v12c0,0.6,0.5,1,1,1s1-0.4,1-1v-12C13,11.6,12.5,11.1,12,11.1z" fill="#3F51B5"></path> <path d="M20,11.1c-0.5,0-1,0.5-1,1v12c0,0.6,0.5,1,1,1s1-0.4,1-1v-12C21,11.6,20.5,11.1,20,11.1z" fill="#3F51B5"></path> <path d="M28,5h-5V4c0-1.7-1.3-3-3-3h-4h-4c-1.7,0-3,1.3-3,3v1H4C3.5,5,3,5.5,3,6s0.5,1,1,1h12h12c0.5,0,1-0.5,1-1 S28.5,5,28,5z" fill="#3F51B5"></path> </g> </g> <g id="Tag"></g> <g id="Link"></g> <g id="Like"></g> <g id="Bad"></g> <g id="Gallery"></g> <g id="Add"></g> <g id="Close"></g> <g id="Forward"></g> <g id="Back"></g> <g id="Buy"></g> <g id="Mac"></g> <g id="Laptop"></g> </g></svg>
                    :""} &nbsp;&nbsp;&nbsp; */}
                {formData.resume ? (
                  <svg
                    onClick={() => deleteFileByName(formData.resume)}
                    height={"30px"}
                    width={"30px"}
                    viewBox="0 0 32 32"
                    enableBackground="new 0 0 32 32"
                    version="1.1"
                    xmlSpace="preserve"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#000000"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <g id="Home"></g> <g id="Print"></g> <g id="Mail"></g>{" "}
                      <g id="Camera"></g> <g id="Video"></g> <g id="Film"></g>{" "}
                      <g id="Message"></g> <g id="Telephone"></g>{" "}
                      <g id="User"></g> <g id="File"></g> <g id="Folder"></g>{" "}
                      <g id="Map"></g> <g id="Download"></g> <g id="Upload"></g>{" "}
                      <g id="Video_Recorder"></g> <g id="Schedule"></g>{" "}
                      <g id="Cart"></g> <g id="Setting"></g> <g id="Search"></g>{" "}
                      <g id="Pencils"></g> <g id="Group"></g>{" "}
                      <g id="Record"></g> <g id="Headphone"></g>{" "}
                      <g id="Music_Player"></g> <g id="Sound_On"></g>{" "}
                      <g id="Sound_Off"></g> <g id="Lock"></g>{" "}
                      <g id="Lock_open"></g> <g id="Love"></g>{" "}
                      <g id="Favorite"></g> <g id="Film_1_"></g>{" "}
                      <g id="Music"></g> <g id="Puzzle"></g>{" "}
                      <g id="Turn_Off"></g> <g id="Book"></g> <g id="Save"></g>{" "}
                      <g id="Reload"></g>{" "}
                      <g id="Trash">
                        {" "}
                        <path
                          d="M28,5h-5V4c0-1.7-1.3-3-3-3h-4h-4c-1.7,0-3,1.3-3,3v1H4C3.5,5,3,5.5,3,6s0.5,1,1,1h1v19c0,2.8,2.2,5,5,5h6 h6c2.8,0,5-2.2,5-5V7h1c0.5,0,1-0.5,1-1S28.5,5,28,5z"
                          fill="#FE9803"
                        ></path>{" "}
                        <g>
                          {" "}
                          <path
                            d="M12,11.1c-0.5,0-1,0.5-1,1v12c0,0.6,0.5,1,1,1s1-0.4,1-1v-12C13,11.6,12.5,11.1,12,11.1z"
                            fill="#3F51B5"
                          ></path>{" "}
                          <path
                            d="M20,11.1c-0.5,0-1,0.5-1,1v12c0,0.6,0.5,1,1,1s1-0.4,1-1v-12C21,11.6,20.5,11.1,20,11.1z"
                            fill="#3F51B5"
                          ></path>{" "}
                          <path
                            d="M28,5h-5V4c0-1.7-1.3-3-3-3h-4h-4c-1.7,0-3,1.3-3,3v1H4C3.5,5,3,5.5,3,6s0.5,1,1,1h12h12c0.5,0,1-0.5,1-1 S28.5,5,28,5z"
                            fill="#3F51B5"
                          ></path>{" "}
                        </g>{" "}
                      </g>{" "}
                      <g id="Tag"></g> <g id="Link"></g> <g id="Like"></g>{" "}
                      <g id="Bad"></g> <g id="Gallery"></g> <g id="Add"></g>{" "}
                      <g id="Close"></g> <g id="Forward"></g> <g id="Back"></g>{" "}
                      <g id="Buy"></g> <g id="Mac"></g> <g id="Laptop"></g>{" "}
                    </g>
                  </svg>
                ) : (
                  ""
                )}
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col">
                <button
                  className="btn btn-primary"
                  style={{ backgroundColor: "#0071bd !important" }}
                  type="submit"
                >
                  Save
                </button>
              </div>
              <div className="col">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditPopup;
