import React from 'react';  
import '../css/ConfirmationPopup.css';  

const ConfirmationPopup = ({ show, onClose, onConfirm }) => {  
  if (!show) return null;  

  return (  
    <div className="popup">  
    <div className="popup-content">  
        <h5 style={{ fontSize: '1rem' }}>Are you sure you want to delete this item?</h5>  
        <div className="popup-buttons">  
            <button  className={`filter-button bg-white text-[#0071bd] btn-primary`} onClick={onConfirm} style={{  color: 'white', padding: '0.3rem 0.9rem', transition: 'transform 0.2s' }} onMouseDown={e => e.currentTarget.style.transform = 'scale(0.95)'} onMouseUp={e => e.currentTarget.style.transform = 'scale(1)'}>Yes</button>  
            <button className={`filter-button bg-white text-[#0071bd] btn-primary`} onClick={onClose} style={{  color: 'white', padding: '0.3rem 0.9rem', transition: 'transform 0.2s' }} onMouseDown={e => e.currentTarget.style.transform = 'scale(0.95)'} onMouseUp={e => e.currentTarget.style.transform = 'scale(1)'}>No</button>  
        </div>  
    </div>  
</div> 
  );  
};  

export default ConfirmationPopup;