import React from 'react';
import { Route, BrowserRouter as Router, Routes, Switch } from 'react-router-dom';
import Dashboard from './Dashboard';
import Login from './Login';
import Footer from './Footer';
// ... other imports

const Layout = () => {
    return (
        <Router>
            <div>
                {/* Add your navigation here */}
                <Routes>
                    <Route path="/" exact element={<Login />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    {/* Add more routes as needed */}
                </Routes>
            </div>
            <Footer />
            
        </Router>
    );
};

export default Layout;
